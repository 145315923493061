export const handleCombinedCodes = (invokeFunction: () => void, codes: Array<string>) => {
    const pressed = new Set<string>();

    const handleKeyDown = (event: KeyboardEvent) => {
        pressed.add(event.code);

        if (codes.every((code) => pressed.has(code))) {
            pressed.clear();
            invokeFunction();
        }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
        pressed.delete(event.code);
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
};

export const generateRandomIntBetween = (min: number, max: number): number => {
    return Math.round(Math.random() * (max - min) + min);
};
