export const objectEntriesPolyfill = <T extends Record<string, unknown>>(obj: T) => {
    if (!Object.entries) {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;
        const resArray = new Array(i);

        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    }

    return Object.entries(obj);
};
