import { ERROR_LEVELS, SENTRY_ONE_TRUST } from 'sentry-utils';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { selectIsPaymentScreen } from 'redux/Payment/selectors';

import sentry from 'services/Sentry/SentryInstance';
import { OneTrust } from 'services/OneTrust';
import { initAnalytics } from 'services/Analytics';

import { isRemarketingFlow as checkIsRemarketing } from 'helpers/utilsUpdated';

const DEFAULT_CONFIG = { childList: true, subtree: true };

export const useOneTrustLoaded = (selector = 'onetrust-consent-sdk') => {
    const isPaymentScreen = useSelector(selectIsPaymentScreen);
    const [isOneTrustLoaded, setIsOneTrustLoaded] = useState(false);
    const [isOneTrustFail, setIsOneTrustFail] = useState(false);
    const [isRemarketingFlow, setIsRemarketingFlow] = useState(false);
    const [oneTrust, setOneTrust] = useState(null);

    new Promise(() => {
        const isOneTrustError = localStorage.getItem('isOneTrustError');

        if (isOneTrustError && !isOneTrustFail) {
            setIsOneTrustFail(true);

            sentry.logError(new Error(isOneTrustError), SENTRY_ONE_TRUST, ERROR_LEVELS.CRITICAL, {
                host: window.location.host,
                href: window.location.href,
            });

            return;
        }

        if (document.getElementById(selector) && !isOneTrustLoaded) {
            setIsOneTrustLoaded(true);
        }

        new MutationObserver((_, observer) => {
            if (document.getElementById(selector) && !isOneTrustLoaded) {
                setIsOneTrustLoaded(true);
                observer.disconnect();
            }
        }).observe(document.body, DEFAULT_CONFIG);
    });

    useEffect(() => {
        if (isOneTrustLoaded) {
            const { OneTrust: instance } = window;

            setOneTrust(new OneTrust(instance));
        }
    }, [isOneTrustLoaded]);

    useEffect(() => {
        const isRemarketing = checkIsRemarketing();

        isRemarketing && setIsRemarketingFlow(true);

        if (oneTrust && !isRemarketingFlow) {
            oneTrust?.init();
        }

        if (oneTrust && isRemarketingFlow) {
            oneTrust?.initRemarketing();
        }
    }, [oneTrust]);

    useEffect(() => {
        if (isPaymentScreen && isOneTrustFail) {
            initAnalytics();
        }
    }, [isPaymentScreen, oneTrust]);

    return { isOneTrustLoaded, oneTrust };
};
