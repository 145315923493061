import { useState } from 'react';

import { IUseBehaveContainerProps, IUseBehaveContainerReturn } from 'types/pages/commonTypes';

export const useBehaveContainer = ({
    toNextPage,
    analytic,
    analyticId,
    modalConfig,
}: IUseBehaveContainerProps): IUseBehaveContainerReturn => {
    const [isModal, setIsModal] = useState(false);

    const optionBoxHandler = (value: string) => {
        const selectedValue = Number(value);

        if (selectedValue) {
            analytic.customData(`${analyticId}_sc__click`, { event_label: selectedValue });

            if (modalConfig && selectedValue >= 4) {
                setTimeout(() => {
                    setIsModal(true);

                    analytic.track(`${analyticId}_modal`);
                }, 300);
            } else handleNextPage();
        }
    };

    const handleNextPage = () => {
        setTimeout(() => {
            toNextPage();
        }, 300);
    };

    const buttonModalHandler = () => {
        analytic.customData(`${analyticId}_modal__click`);

        handleNextPage();
    };

    return { isModal, optionBoxHandler, buttonModalHandler };
};
