import { IMPERIAL, METRIC } from 'constants/measureUnits';

import { getToFixedNumber, isMaleGender } from 'helpers/utilsUpdated';

const CONSTANTS = {
    defaultCurrentWeight: 65,
    defaultAge: 35,
    defaultHeight: 162,
    defaultFitnessLevel: 'moderatelyActive',
    activityLevelLitters: {
        1.2: 0,
        1.375: 0.15,
        1.55: 0.4,
        1.725: 0.68,
        1.9: 1.02,
    },
    fitnessLvlToActivityLvl: {
        sedentary: 1.2,
        lightlyActive: 1.375,
        moderatelyActive: 1.55,
        veryActive: 1.725,
        extraActive: 1.9,
    },
};

const CONVERT_COEFICIENT = 2.2046;

class Convert {
    constructor(numberSystem) {
        this.numberSystem = numberSystem || METRIC;
        this.needConvertResult = numberSystem === IMPERIAL;
    }

    fromLbsToKg(lbs) {
        return lbs / CONVERT_COEFICIENT;
    }

    fromKgToLbs(kg) {
        return Math.round(kg * CONVERT_COEFICIENT);
    }
    // this method
    convertWeight(value, fromUnit, toUnit, places) {
        if (fromUnit === toUnit) {
            return getToFixedNumber(Number(value), places);
        }

        if (fromUnit === 'kg') {
            return getToFixedNumber(Number(value) * CONVERT_COEFICIENT, places);
        }

        return getToFixedNumber(Number(value) / CONVERT_COEFICIENT, places);
    }

    fromInToCm(inch) {
        return inch / 0.3937;
    }

    getUnitByNumberSystem(type) {
        const UNITS = {
            IMPERIAL: {
                weight: 'lbs',
                height: 'in',
                volume: 'GAL',
            },
            METRIC: {
                weight: 'kg',
                height: 'cm',
                volume: 'L',
            },
        };

        return UNITS[this.numberSystem][type];
    }

    getBMI(weight, height) {
        return getToFixedNumber(weight / Math.pow(height / 100, 2));
    }

    getLoseWeightByBMI(ВМI) {
        const checkBMI1 = ВМI < 34.99 ? 2 : 2.5;
        const checkBMI2 = ВМI < 29.99 ? 1.5 : checkBMI1;

        const result = ВМI < 24.99 ? 1 : checkBMI2;

        return this.needConvertResult ? this.fromKgToLbs(result) : result;
    }

    getLabelByBMI(ВМI) {
        const getLabelByBMI1 = ВМI < 29.99 ? 'Overweight' : 'Obesity';
        const getLabelByBMI2 = ВМI < 24.99 ? 'Normal' : getLabelByBMI1;

        return ВМI < 18.49 ? 'Underweight' : getLabelByBMI2;
    }

    dailyTargetBMR(
        gender,
        currentWeight = CONSTANTS.defaultCurrentWeight,
        height = CONSTANTS.defaultHeight,
        age = CONSTANTS.defaultAge
    ) {
        const coef = isMaleGender(gender) ? +5 : -161;

        return 10 * +currentWeight + 6.25 * +height - 5 * +age + coef;
    }

    dailyTDE(bmr, activity = CONSTANTS.defaultFitnessLevel) {
        return bmr * CONSTANTS.fitnessLvlToActivityLvl[activity || CONSTANTS.defaultFitnessLevel];
    }

    calorieRangeMin(tde) {
        return 0.95 * tde;
    }

    getCurrentWeight(weight) {
        const fixedWeight = Math.round(Number(weight));

        return this.needConvertResult ? this.fromKgToLbs(fixedWeight) : fixedWeight;
    }

    roundNumber(val, bitness = 10) {
        return Math.round(val / bitness) * bitness;
    }

    getCalorieRangeMax(tde) {
        return 1.05 * tde;
    }

    getWaterPerDay(currentWeight = CONSTANTS.defaultCurrentWeight, activityLevel = CONSTANTS.defaultFitnessLevel) {
        // 41.6745 = 2.205*2/3*28.35;
        const coefByActivityLevel =
            CONSTANTS.activityLevelLitters[
                CONSTANTS.fitnessLvlToActivityLvl[activityLevel || CONSTANTS.defaultFitnessLevel]
            ];
        const litters = (currentWeight * 41.6745) / 1000 + coefByActivityLevel;

        return getToFixedNumber(
            this.needConvertResult ? this.fromLittersToGallons(litters) : getToFixedNumber(litters)
        );
    }

    fromFeetAndInchesToCentimeters(ft, inch) {
        const feetInCM = Number(ft) * 30.48;
        const inchesInCM = Number(inch) * 2.54;

        return getToFixedNumber(feetInCM + inchesInCM);
    }
}

export default Convert;
