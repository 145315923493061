import { CurrentProduct } from 'types/payments/payments';

import { APP_FULL_ACCESS } from './payments';

export const EXCLUSIVE_OFFER_PRODUCT: CurrentProduct = {
    product_code: APP_FULL_ACCESS,
    payment_type: 'subscription',
    id: '04207343-3730-4fcb-b541-712afc219161',
    price: 99,
    name: 'One-week intro (0.99) - Three-months (29.99)',
    trial: 7,
    period: 90,
    price_per_state: 1,
    start_price_per_state: 1,
    is_preselected: false,
    start_price: 2999,
    product_state: 'per_day',
    ltv: 1500,
};
