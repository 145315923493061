import api from 'api';

import { setIsPaid, setUserData, removeToken } from 'redux/User/actions';
import { setCompleteOBs, setCurrenyBranchName, setTestaniaLoginStatus, clearCompleteOBs } from 'redux/Testania/actions';
import { setSolidInited } from 'redux/Payment/actions';
import { setMeasure } from 'redux/Onboadring/actions';

import { onboardingScreensList } from 'constants/onboardingScreensList';
import { IMPERIAL, METRIC } from 'constants/measureUnits';
import { instructionsPagesUrlList } from 'constants/instructionsPagesUrlList';
import { COUNTRIES_IMPERIAL_SYSTEM } from 'constants/countriesList';

import { deleteSlash, isEmpty, replaceUnderscore } from 'helpers/utilsUpdated';

export const isInstructionPage = () => {
    const path = deleteSlash(window.location.pathname);

    return instructionsPagesUrlList.includes(path);
};

export const getBranchNameFromLocalStorage = () => {
    return localStorage.getItem('config') && JSON.parse(localStorage.getItem('config'))?.branch_name;
};

export const getConfigBy = (name, defaultValue) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(name) || getBranchNameFromLocalStorage() || defaultValue;
};

export const getVariantBranchName = () => {
    const params = new URLSearchParams(window.location.search);

    return params.get('variant-name');
};

export const getCheckFlow = (payment_flow) => (flowId) => payment_flow.find((item) => item.id === flowId);

export const getValidatedStatus = (rules) => {
    for (const rule of rules) {
        if (!rule.exec(rule.args)) {
            /* eslint-disable no-console */
            console.log('%c Flow validation error - ' + rule.message, 'color:red;');

            return false;
        }
    }

    return true;
};

export const getScreenList = (onboardingFlow) => {
    let count = 1;

    return onboardingFlow.reduce((screenList, { id }) => {
        const onboardingFlowPage = replaceUnderscore(id);

        if (onboardingScreensList.includes(onboardingFlowPage)) {
            return { ...screenList, [onboardingFlowPage]: { index: count++ } };
        }

        return screenList;
    }, {});
};

export const getIsLogin = async (testaniaLoginStatus, token) => {
    return testaniaLoginStatus === 'true' || Boolean(token) || false;
};

const getUserSetData = async (dispatch) => {
    let user = {};

    try {
        user = await api.user.getUser();

        !isEmpty(user) && dispatch(setUserData(user));
    } catch (e) {
        console.error('getUser error', e);
    }

    return { isPaid: Boolean(user?.is_paid), isPasswordSet: Boolean(user?.is_password) };
};

export const getUserStatus = async (dispatch, token) => {
    const userStatus = token ? await getUserSetData(dispatch) : { isPaid: false, isPasswordSet: false };

    dispatch(setIsPaid(userStatus?.isPaid));

    return userStatus;
};

export const setCurrentBranchToStore = async (branch, dispatch) => {
    dispatch(setCurrenyBranchName(branch));
};

export const setCompleteOBsToStore = async (branch, dispatch) => {
    dispatch(setCompleteOBs(branch));
};

export const clearCompleteOBsToStore = async (dispatch) => {
    dispatch(removeToken());
    dispatch(setSolidInited(false));
    dispatch(clearCompleteOBs());
};

export const setTestaniaLoginStatusToStore = async (status, dispatch) => {
    dispatch(setTestaniaLoginStatus(status));
};

export const setInitMeasure = async (country, dispatch) => {
    const measure = COUNTRIES_IMPERIAL_SYSTEM.includes(country) ? IMPERIAL : METRIC;

    dispatch(setMeasure(measure));
};
