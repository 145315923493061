import classNames from 'classnames/bind';

import styles from './ModalClose.module.css';

import { IModalCloseProps } from './types';

const cx = classNames.bind(styles);

function ModalClose({
    notificationModalIcon,
    isLeftClose,
    onClick = () => {},
    isFixed,
    isSmallClose,
    withTimer,
    isActiveTimer,
    dataLocator,
}: IModalCloseProps) {
    const modalStyles = cx('closePopup', {
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
        closePopup__checkoutWithTimerOn: withTimer && isActiveTimer,
        closePopup__checkoutWithTimerOff: withTimer && !isActiveTimer,
    });

    return <span data-locator={dataLocator} className={modalStyles} onClick={onClick} role="button" tabIndex={0} />;
}

export default ModalClose;
