import { useEffect, useState } from 'react';

export const usePaymentAnimation = () => {
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changeAnimation);

        return () => removeEventListener('scroll', changeAnimation);
    }, []);

    const changeAnimation = () => {
        const screen = window.innerHeight;
        const position = document.querySelector('button[data-locator="startPaymentBtn"]')?.getBoundingClientRect()?.y;

        if (position) {
            setIsAnimated(position < screen - 60 && position > 0);
        }
    };

    return {
        isAnimated,
    };
};
