import { Provider } from 'wikr-core-analytics';
import { ERROR_LEVELS, SENTRY_ANALYTIC } from 'sentry-utils';

import { setPlatform } from 'redux/User/actions';

import sentry from 'services/Sentry/SentryInstance';

import { getDeviceOS } from 'helpers/utilsUpdated';

import { Dispatch } from 'types/commonInterfaces';
import { IAnalyticsData } from './types';

const getResolveParams = async (value: unknown) => {
    return new Promise((resolve) => {
        resolve(value);
    });
};

export const setAnalyticData = async ({ country, abTestName, userId }: IAnalyticsData) => {
    if (userId) {
        await Provider.setUserId(Number(userId));
    }

    await Provider.provideData({
        country: async () => await getResolveParams(country),
        abTestName: async () => await getResolveParams(abTestName),
        clientHandlerFn: (error: Error) => sentry.logError(error, SENTRY_ANALYTIC, ERROR_LEVELS.ERROR, {}),
    });
};

export const setUserPlatform = (dispatch: Dispatch) => {
    const platform = getDeviceOS();

    dispatch(setPlatform(platform));
};

export const reload = () => window.location.reload();
