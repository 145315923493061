import { Colors } from 'wikr-core-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';

import { DAYS_PER_WEEK, LATAM_SPECIAL_MEX_CURRENCY } from 'constants/payments';
import { MEXICAN_COUNTRY_CODE } from 'constants/countriesList';

import { IPaymentFlow } from 'hooks/types';

import { getCardsConfigFoxtrot16, getProductPaymentDataWithSubsHelper } from 'helpers/utilsUpdated';
import { getStartPriceFromProductWithoutTrial } from 'helpers/payments';

import { CurrentProduct } from 'types/payments/payments';

import { Dayjs } from 'dayjs';

const HARDCODED_TRIAL_DURATION = { count: 7, context: 'day' };

export const useCardsAndDisclaimerConfig = ({
    currentProductForPayment,
    timerTime,
    products,
    pageInfo,
    defaultTrialPrice,
    withFullPrice,
    isLatamSpecial = false,
}: {
    currentProductForPayment: CurrentProduct;
    timerTime: { time_to: Dayjs; seconds: number };
    products: CurrentProduct[];
    pageInfo: IPaymentFlow;
    defaultTrialPrice: number;
    withFullPrice?: boolean;
    isLatamSpecial?: boolean;
}) => {
    const { currency } = useSelector(selectPayment);
    const country = useSelector(selectCountry);

    const finalCurrency = isLatamSpecial && country === MEXICAN_COUNTRY_CODE ? LATAM_SPECIAL_MEX_CURRENCY : currency;

    const paymentTypeData = getProductPaymentDataWithSubsHelper(currentProductForPayment);

    const { t } = useTranslation();

    const fullPriceTrial = pageInfo?.config?.trialFullPrice || defaultTrialPrice;

    // TODO: useMemo const for fullPrice
    const getDisclaimerWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOn', {
                  fullPrice: isLatamSpecial
                      ? getStartPriceFromProductWithoutTrial({
                            products,
                            trialProductPeriod: currentProductForPayment.period,
                            defaultTrialPrice,
                        })
                      : fullPriceTrial,
                  currency: finalCurrency.sign,
                  count1: HARDCODED_TRIAL_DURATION.count,
                  count2: paymentTypeData.paymentLabel?.count,
                  context1: HARDCODED_TRIAL_DURATION.context,
                  context2: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentIntroductory.basicDisclaimer.cancelFE', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: finalCurrency.sign,
              });

    const getDisclaimerWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG4Foxtrot16.disclaimerTrial.timerOff', {
                  fullPrice: isLatamSpecial
                      ? getStartPriceFromProductWithoutTrial({
                            products,
                            trialProductPeriod: currentProductForPayment.period,
                            defaultTrialPrice,
                        })
                      : fullPriceTrial,
                  currency: finalCurrency.sign,
                  count1: HARDCODED_TRIAL_DURATION.count,
                  count2: paymentTypeData.paymentLabel?.count,
                  context1: HARDCODED_TRIAL_DURATION.context,
                  context2: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentIntroductory.basicDisclaimerForRegularProduct.cancelFE', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: finalCurrency.sign,
              });

    const getDisclaimerCheckoutWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.checkoutFoxtrot16.disclaimerTrial.timerOn', {
                  fullPrice: isLatamSpecial
                      ? getStartPriceFromProductWithoutTrial({
                            products,
                            trialProductPeriod: currentProductForPayment.period,
                            defaultTrialPrice,
                        })
                      : fullPriceTrial,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: finalCurrency.sign,
                  count1: paymentTypeData.paymentLabel?.count,
                  count2: HARDCODED_TRIAL_DURATION.count,
                  context1: paymentTypeData.paymentLabel?.context,
                  context2: HARDCODED_TRIAL_DURATION.context,
              })
            : t('paymentFlow.checkoutFoxtrot16.disclaimer.timerOn', {
                  fullPrice: paymentTypeData.fullPrice,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: finalCurrency.sign,
                  context: paymentTypeData.paymentLabel?.context,
                  count: +paymentTypeData.paymentLabel?.count,
              });

    const getDisclaimerCheckoutWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.checkoutFoxtrot16.disclaimerTrial.timerOff', {
                  fullPrice: isLatamSpecial
                      ? getStartPriceFromProductWithoutTrial({
                            products,
                            trialProductPeriod: currentProductForPayment.period,
                            defaultTrialPrice,
                        })
                      : fullPriceTrial,
                  trialPrice: paymentTypeData.trialPrice,
                  currency: finalCurrency.sign,
                  count1: paymentTypeData.paymentLabel?.count,
                  count2: HARDCODED_TRIAL_DURATION.count,
                  context1: paymentTypeData.paymentLabel?.context,
                  context2: HARDCODED_TRIAL_DURATION.context,
              })
            : t('paymentFlow.checkoutFoxtrot16.disclaimer.timerOff', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: finalCurrency.sign,
                  context: paymentTypeData.paymentLabel?.context,
                  count: +paymentTypeData.paymentLabel?.count,
              });

    const [disclaimerText, setDisclaimerText] = useState('');
    const [disclaimerCheckoutText, setDisclaimerCheckoutText] = useState('');
    const [isTrialOneWeek, setIsTrialOneWeek] = useState<boolean | undefined>();

    const disclaimerCheckoutColor: Colors = isTrialOneWeek ? 'text-main' : 'text-secondary';

    useEffect(() => {
        setIsTrialOneWeek(currentProductForPayment.trial === DAYS_PER_WEEK);
    }, [currentProductForPayment, timerTime]);

    useEffect(() => {
        if (timerTime?.seconds > 0) {
            setDisclaimerText(getDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek));
            setDisclaimerCheckoutText(getDisclaimerCheckoutWithWeekTrialBeforeTimer(isTrialOneWeek));
        } else {
            setDisclaimerText(getDisclaimerWithWeekTrialAfterTimer(isTrialOneWeek));
            setDisclaimerCheckoutText(getDisclaimerCheckoutWithWeekTrialAfterTimer(isTrialOneWeek));
        }
    }, [timerTime, currentProductForPayment, isTrialOneWeek]);

    const cardsConfigure = getCardsConfigFoxtrot16(products, t, withFullPrice);

    return {
        isTrialOneWeek,
        disclaimerText,
        cardsConfigure,
        disclaimerCheckoutText,
        disclaimerCheckoutColor,
    };
};
