/* eslint-disable max-lines */
import * as actionTypes from './actionTypes';

import {
    IActivityLevel,
    IAgeGroup,
    MeasurePayload,
    OccasionList,
    OnboardingScreensList,
    SkipOnboarding,
} from 'types/onboarding/onboardingStore';
import { IGender } from 'types/commonInterfaces';

export function setUrlParams(url: { [key: string]: string } | null) {
    return {
        type: actionTypes.SET_URL_PARAMS,
        payload: url,
    } as const;
}

export function setGender(token: IGender) {
    return {
        type: actionTypes.SET_GENDER,
        payload: token,
    } as const;
}

export function setGoal(goal: string | string[]) {
    return {
        type: actionTypes.SET_GOAL,
        payload: goal,
    } as const;
}

export function setCurrentBody(body_type: string) {
    return {
        type: actionTypes.SET_CURRENT_BODY,
        payload: body_type,
    } as const;
}

export function setCurrentWeight(current_weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_CURRENT_WEIGHT,
        payload: current_weight_with_unit,
    } as const;
}

export function setTargetBody(body_type: string) {
    return {
        type: actionTypes.SET_TARGET_BODY,
        payload: body_type,
    } as const;
}

export function setTargetZone(body_type: string) {
    return {
        type: actionTypes.SET_TARGET_ZONE,
        payload: body_type,
    } as const;
}

export function setTargetWeight(weight_with_unit: MeasurePayload) {
    return {
        type: actionTypes.SET_TARGET_WEIGHT,
        payload: weight_with_unit,
    } as const;
}

export function setActivityLvl(activityLevel: IActivityLevel) {
    return {
        type: actionTypes.SET_ACTIVITY_LVL,
        payload: activityLevel,
    } as const;
}

export function setMeasure(system: string) {
    return {
        type: actionTypes.SET_MEASURE_SYSTEM,
        payload: system,
    } as const;
}

export function setFitnessLevel(fitness_level: string) {
    return {
        type: actionTypes.SET_FITNESS_LEVEL,
        payload: fitness_level,
    } as const;
}

export function setFastingMotivation(fasting_motivation: string) {
    return {
        type: actionTypes.SET_MOTIVATION,
        payload: fasting_motivation,
    } as const;
}

export function setLifeStyle(style: string) {
    return {
        type: actionTypes.SET_LIFE_STYLE,
        payload: style,
    } as const;
}

export function setLunchTime(lunchTime: string) {
    return {
        type: actionTypes.SET_LUNCH_TIME,
        payload: lunchTime,
    } as const;
}

export function setDinnerTime(dinnerTime: string) {
    return {
        type: actionTypes.SET_DINNER_TIME,
        payload: dinnerTime,
    } as const;
}

export function setBreakfastTime(breakfastTime: string) {
    return {
        type: actionTypes.SET_BREAKFAST_TIME,
        payload: breakfastTime,
    } as const;
}

export function setAge(age: string | number) {
    return {
        type: actionTypes.SET_AGE,
        payload: age,
    } as const;
}

export function setTall(tall: MeasurePayload) {
    return {
        type: actionTypes.SET_TALL,
        payload: tall,
    } as const;
}

export function setOnboardingScreensList(screensList: OnboardingScreensList) {
    return {
        type: actionTypes.SET_ONBOARDING_SCREENS_LIST,
        payload: screensList,
    } as const;
}

export function setOccasion(occasionList: OccasionList) {
    return {
        type: actionTypes.SET_OCCASION,
        payload: occasionList,
    } as const;
}
export function setOccasionDate(occasionDate: Date) {
    return {
        type: actionTypes.SET_OCCASION_DATE,
        payload: occasionDate,
    } as const;
}

export function initAutoLogin() {
    return {
        type: actionTypes.INIT_AUTO_LOGIN,
    } as const;
}

export function setFastingFamiliarity(fastingFamiliarity: string) {
    return {
        type: actionTypes.SET_FASTING_FAMILIARITY,
        payload: fastingFamiliarity,
    } as const;
}

export function setFastingLevel(fastingLevel: string) {
    return {
        type: actionTypes.SET_FASTING_LEVEL,
        payload: fastingLevel,
    } as const;
}

export function setJobActive(jobActive: string) {
    return {
        type: actionTypes.JOB_ACTIVE,
        payload: jobActive,
    } as const;
}

export function setAgeGroup(ageGroup: IAgeGroup) {
    return {
        type: actionTypes.SET_AGE_GROUP,
        payload: ageGroup,
    } as const;
}

export function setFeelings(feelings: string) {
    return {
        type: actionTypes.SET_FEELINGS,
        payload: feelings,
    } as const;
}

export function setDiet(diet: string) {
    return {
        type: actionTypes.SET_DIET,
        payload: diet,
    } as const;
}

export function setExercise(exercise: string) {
    return {
        type: actionTypes.SET_EXERCISE,
        payload: exercise,
    } as const;
}

export function skipOnboarding(payload: SkipOnboarding) {
    return {
        type: actionTypes.SKIP_ONBOARDING,
        payload: payload,
    } as const;
}
