import UserApi from './components/User';
import PaymentApi from './components/Payment';

function ApiInstance() {
    const user = new UserApi();
    const payment = new PaymentApi();

    return {
        user,
        payment,
    };
}

export default ApiInstance();
