import { useEffect } from 'react';

const appHeight = () => {
    const doc = document.documentElement;

    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};

export function useIOSvhFix() {
    useEffect(() => {
        window.addEventListener('resize', appHeight);

        appHeight();

        return () => {
            window.removeEventListener('resize', appHeight);
        };
    }, [window.innerHeight]);
}
