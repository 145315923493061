import dayjs from 'dayjs';

class Date {
    constructor() {}

    getTime() {
        return dayjs();
    }

    getDiff(fromDate, toDate) {
        const dateCurr = dayjs(fromDate);
        const dateTo = dayjs(toDate);
        const diff = dateTo.diff(dateCurr);
        const differentMinSec = dayjs(diff);

        return { time_to: differentMinSec, seconds: diff };
    }

    addMinutes(addMin) {
        // it will add 10 mins in the current time and will give time in 03:35 PM format; can use m or minutes
        return dayjs().add(addMin, 'minutes').toDate();
    }
}

export default new Date();
