import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { selectMeasureSystem } from 'redux/Onboadring/selectors';
import { setMeasure } from 'redux/Onboadring/actions';

import { MEASURE_SYSTEM } from 'constants/measureUnits';

import { cleanUpUnit } from 'helpers/measureUnitHelpers';

export interface UseMeasureUnitReturn {
    measureSystem: string;
    setMeasureSystem: (unit: string) => void;
    getMeasureSystem: (unit: string) => string;
}

export function useMeasureUnit(): UseMeasureUnitReturn {
    const measureSystem: string = useSelector(selectMeasureSystem);
    const dispatch = useDispatch();

    const [measureSystemValue, setMeasureSystemValue] = useState(measureSystem);

    const setMeasureSystem = (unit: string) => {
        dispatch(setMeasure(unit));

        setMeasureSystemValue(unit);
    };

    const getMeasureSystem = (unit: string) => MEASURE_SYSTEM[cleanUpUnit(unit)];

    return {
        measureSystem: measureSystemValue,
        setMeasureSystem,
        getMeasureSystem,
    };
}
