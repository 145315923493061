export const OCCASION_COEFFICIENT = {
    first: 0.75,
    second: 0.85,
    third: 0.88,
    final: 1,
};

export enum WeightGoal {
    GAIN = 'gain',
    LOSE = 'lose',
    MAINTAIN = 'maintain',
}
