import { DEFAULT_NAVIGATOR_LANGUAGE, AVAILABLE_LOCALIZATIONS, LATAM_LOCALIZATIONS } from 'constants/localization';

import { getLanguage } from './utilsUpdated';

export const getNavigatorLanguage = () => {
    let language;

    if (navigator?.languages?.length) {
        language = navigator.languages[0];
    } else {
        language = navigator?.language || DEFAULT_NAVIGATOR_LANGUAGE;
    }

    return language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage);

    return isLanguageLocalized ? navigatorLanguage : null;
};

export const getCurrentLocalization = () => {
    const routerLang = window.location.pathname.split('/')[1];

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(routerLang);

    return isLanguageLocalized ? routerLang : null;
};

export const isLatamLocalization = () => {
    const language = getLanguage();

    return LATAM_LOCALIZATIONS.includes(language);
};
