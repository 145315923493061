import { IMPERIAL, TALL_UNIT, WEIGHT_UNIT } from 'constants/measureUnits';
import { LATAM_LBS_KEY, LATAM_IN_KEY } from 'constants/localization';

import { isLatamLocalization } from './localization';

export const cleanUpUnit = (unit: string): string => (unit.includes('.') ? unit.split('.')[1] : unit);

export const getWeightUnit = (measureSystem: string) => {
    return isLatamLocalization() && measureSystem === IMPERIAL ? LATAM_LBS_KEY : WEIGHT_UNIT[measureSystem];
};

export const getHeightUnit = (measureSystem: string) => {
    return isLatamLocalization() && measureSystem === IMPERIAL ? LATAM_IN_KEY : TALL_UNIT[measureSystem];
};
