import { useState } from 'react';

import { useAnalytics, usePreloadNextPage, useScrollToTop } from 'hooks';

import { IUseObModalArgs, IUseObModalReturn } from 'types/pages/commonTypes';

export const useObModal = ({
    getNextPage,
    toNextPage,
    analyticId,
    analyticIdModal,
}: IUseObModalArgs): IUseObModalReturn => {
    useScrollToTop();
    usePreloadNextPage({ getNextPage });
    const { customData } = useAnalytics();

    const [isModal, setIsModal] = useState<boolean>(false);
    const [optionValue, setOptionValue] = useState<string>('');

    const handleToOptionClick = (body: string): void => {
        customData(`${analyticId}__click`, {
            event_label: body,
        });

        setOptionValue(body);

        setIsModal(true);
    };

    const handleToNextClick = (): void => {
        analyticIdModal.length && customData(`${analyticIdModal}__click`);

        toNextPage?.();
    };

    const getHandleToNextClick = (customAnalyticIdModal: string) => (): void => {
        customData(`${customAnalyticIdModal}__click`);

        toNextPage?.();
    };

    return { isModal, optionValue, handleToOptionClick, handleToNextClick, getHandleToNextClick };
};
