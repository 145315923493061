/* eslint-disable max-lines */
import { PAYMENT_METHOD_NAME, PAYMENT_TYPES } from 'constants/payments';

import {
    parsePaymentType,
    periodNormalizer,
    getPriceFromCents,
    trialNormalizer,
    getMaxElemFromArr,
    getMinElemFromArr,
    getDiscount,
} from 'helpers/utilsUpdated';
import lazyWithRetry from 'helpers/lazyWithRetry';

import Convert from './Convert';

const convertUnits = new Convert();

export const getKgWeight = (data) => {
    const { unit, value } = data;

    return unit === 'lbs' ? convertUnits.fromLbsToKg(value) : value;
};

export const concatUrlParams = (urlParams) => {
    return Object.entries(urlParams)
        .map((item) => `&${item[0]}=${item[1]}`)
        .join('');
};

export const isEmpty = (value) => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    return parts.length === 2 ? parts.pop().split(';').shift() : null;
};

export const getPaymentNameByMethod = (paymentMethod) => {
    try {
        return PAYMENT_METHOD_NAME[paymentMethod];
    } catch (e) {
        console.error(e);
        throw Error(`no payment method founded in PAYMENT_METHOD_NAME with name ${paymentMethod}`);
    }
};

export const getPaymentIdByMethod = (paymentMethod) => {
    try {
        return PAYMENT_TYPES[paymentMethod];
    } catch (e) {
        console.error(e);
        throw Error(`no payment method founded in PAYMENT_TYPES with name ${paymentMethod}`);
    }
};

export const getProductPaymentData = (currentProduct) => {
    const { trial, period, start_price, price } = currentProduct;

    const currentPaymentType = parsePaymentType(trial);
    const paymentType = trial === period ? 'SEVERAL_MONTH_PLAN' : 'ONE_WEEK_PLAN';
    const paymentLabel = periodNormalizer(period)?.toLowerCase();
    const fullPrice = getPriceFromCents(start_price);
    const trialPrice = getPriceFromCents(price);
    const trialLabel = trialNormalizer(trial);

    return { paymentType, currentPaymentType, paymentLabel, fullPrice, trialPrice, trialLabel };
};

export const getDynamicallyDiscount = ({ products, selectedPeriod, currentPrice }) => {
    const productsByPeriod = products.filter((product) => product.trial === selectedPeriod);
    const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

// TODO - needs refactor, because upper function is a duplicate (kostyl` for new payments, fix trial -> period)
export const getDynamicallyDiscountFixed = ({ products, selectedPeriod, currentPrice }) => {
    const productsByPeriod = products.filter((product) => product.period === selectedPeriod);
    const maxElemFromArr = getMaxElemFromArr(productsByPeriod, 'price');
    const introductoryPriceMax = maxElemFromArr?.price;

    return Math.round((1 - currentPrice / introductoryPriceMax) * 100);
};

export function lazyWithPreload(factory) {
    const Component = lazyWithRetry(factory);

    Component.preload = factory;

    return Component;
}

export const disableInputMoreThatMaxFieldLength = (element) => {
    // requires element's maxLength attribute defined
    if (element.value.length > element.maxLength) {
        element.value = element.value.slice(0, element.maxLength);
    }
};

export const getTrimmedFields = (fields) => {
    Object.keys(fields).forEach((k) => {
        if (typeof fields[k] === 'string') {
            fields[k] = fields[k].trim();
        }
    });

    return fields;
};

export const isShortPayment = () => {
    const payment_flow = JSON.parse(localStorage.getItem('config'))?.payment_flow;

    if (isEmpty(payment_flow)) {
        return false;
    }

    const id = payment_flow?.find(({ parent_id }) => parent_id === null)?.id;

    return payment_flow?.some(({ parent_id, is_paid }) => parent_id === id && !is_paid);
};

export const getDiscountForPayment = (currentProduct, pageInfo) => {
    const selectedPeriod = currentProduct?.trial;
    const currentPrice = currentProduct?.price;

    return getDynamicallyDiscount({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

// TODO - needs refactor, because upper function is a duplicate (kostyl` for new payments, fix trial -> period)
export const getDiscountForPaymentFixed = (currentProduct, pageInfo) => {
    const selectedPeriod = currentProduct?.period;
    const currentPrice = currentProduct?.price;

    return getDynamicallyDiscountFixed({ products: pageInfo?.products, selectedPeriod, currentPrice });
};

export const getDiscountFromFirstPayment = (currentProductPeriod, products = []) => {
    const productsByPeriodFromFirstPayment = products.filter((product) => product?.period === currentProductPeriod);

    if (productsByPeriodFromFirstPayment.length < 2) {
        return 0;
    }

    const minPricedProduct = getMinElemFromArr(productsByPeriodFromFirstPayment, 'price');
    const maxPricedProduct = getMaxElemFromArr(productsByPeriodFromFirstPayment, 'price');

    return getDiscount(maxPricedProduct.price, minPricedProduct.price);
};

const fromEntriesPolyfill = (iterable) => {
    return [...iterable].reduce((obj, [key, val]) => ({ ...obj, [key]: val }), {});
};

export const getUrlParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)?.entries();

    const params = fromEntriesPolyfill(urlSearchParams);

    return isEmpty(params) ? null : params;
};
