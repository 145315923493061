import ApiClient from 'services/ApiClient';

import { ValidateResponse, PostInitResponse } from 'types/payments/validate';
import {
    ValidateData,
    UpsaleData,
    PaymentData,
    IPaymentFailToReteno,
    IPaymentFailToRetenoResponse,
    PostInitData,
} from 'types/payments/paymentApiInterfaces';

class PaymentApi {
    async validate<Data = ValidateData, Response = ValidateResponse>(payload: Data): Promise<Response | unknown> {
        return await ApiClient.post<Data, Response>('payments/validate', payload);
    }

    async postInit<Data = PostInitData, Response = PostInitResponse>(payload: Data): Promise<Response | unknown> {
        return await ApiClient.post<Data, Response>('payments/post-init', payload);
    }

    async upSale<Data = UpsaleData, Response = PaymentData>(payload: Data): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('payments/recurring', payload);
    }

    async paymentFailToReteno<Data = IPaymentFailToReteno, Response = IPaymentFailToRetenoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('reteno/event', payload);
    }
}

export default PaymentApi;
