import { uaParser } from 'wikr-core-analytics';
import {
    SENTRY_AXIOS,
    IOS,
    ANDROID,
    CHROME,
    IOS_MIN_VERSION,
    ANDROID_MIN_VERSION,
    CHROME_MIN_VERSION,
} from 'sentry-utils';
import { Event } from '@sentry/types';

import { objectEntriesPolyfill } from 'helpers/polyfills';

import { Extras, errorType, Tags, IError } from './interfaces';

export const getCustomTags = (error: IError, type: errorType, extras?: Extras): Tags => {
    const customTags: Record<string, string> = {};

    if (type === SENTRY_AXIOS) {
        customTags.API_ERROR_CODE = extras?.status as string;
    }

    return objectEntriesPolyfill(customTags);
};

export const checkIsOldDevices = (event: Event) => {
    const UAParser = new uaParser();
    const userAgent = UAParser.setUA(event?.extra?.agent as string);

    const browser = userAgent?.getBrowser();
    const deviceOS = userAgent?.getOS();

    const parsedOSVersion = parseFloat(deviceOS?.version || '');
    const parsedBrowserVersion = parseFloat(browser?.major || '');

    return (
        (deviceOS?.name === IOS && parsedOSVersion < IOS_MIN_VERSION) ||
        (deviceOS?.name === ANDROID && parsedOSVersion < ANDROID_MIN_VERSION) ||
        (browser?.name === CHROME && parsedBrowserVersion < CHROME_MIN_VERSION)
    );
};
