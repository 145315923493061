import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const selectTestania = (state: RootState) => state.testania;

const selectOnboardingConfig = createSelector(selectTestania, (testania) => testania.onboardingConfig);
const selectTestaniaName = createSelector(selectTestania, (testania) => testania.testaniaName);
const selectTestaniaLoginStatus = createSelector(selectTestania, (testania) => testania.testaniaLoginStatus);
const selectCompleteOBs = createSelector(selectTestania, (testania) => testania.completeOBs);
const selectCurrantBranchName = createSelector(selectTestania, (testania) => testania.currantBranchName);
const selectFlowLink = createSelector(selectTestania, (testania) => testania.flowLink);

export {
    selectTestania,
    selectOnboardingConfig,
    selectTestaniaName,
    selectTestaniaLoginStatus,
    selectCompleteOBs,
    selectCurrantBranchName,
    selectFlowLink,
};
