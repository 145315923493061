/* eslint-disable max-lines */
import { Main } from 'wikr-core-analytics';
import Core from 'testania';
import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';

import api from 'api';

import { selectCountry } from 'redux/User/selectors';
import * as actionTypes from 'redux/User/actionTypes';
import {
    signUp,
    setToken,
    setUserId,
    setUserEmail,
    enableIsLoading,
    disableIsLoading,
    updateUserInfo,
    setDeepLinks,
    setIsAutologinComplete,
    setIsPaid,
    updateConsentMailing,
    setUserData,
    setUpdatedAtTime,
    setConsentMailingActive,
    getDeepLinks,
} from 'redux/User/actions';
import { setCurrency as setCurrencyToStore } from 'redux/Payment/actions';
import { selectUrlParams, selectAge } from 'redux/Onboadring/selectors';
import { INIT_AUTO_LOGIN } from 'redux/Onboadring/actionTypes';
import {
    setGender,
    setGoal,
    setMeasure,
    setCurrentWeight,
    setTargetWeight,
    setTall,
    setTargetBody,
    setFitnessLevel,
} from 'redux/Onboadring/actions';

import { DAY_IN_MILLISECONDS } from 'constants/timeValues';
import { PAYMENT_DEFAULT_CURRENCY, LTV_COEFFICIENT_KEY } from 'constants/payments';
import { METRIC } from 'constants/measureUnits';
import { DEFAULT_LANGUAGE } from 'constants/localization';
import { COUNTRIES_CURRENCY_LIST } from 'constants/countriesList';

import sentry from 'services/Sentry/SentryInstance';

import { isEmpty, getParamFromUrl } from 'helpers/utilsUpdated';
import { getCurrentLocalization, getLocalizationFromAvailable } from 'helpers/localization';

import { UserInfoResponse, DeepLinkData } from 'types/user/userApiInterface';
import { GetUserResponse } from 'types/user/getUser';
import { IGender } from 'types/commonInterfaces';

// TODO: refactor IGender later
type LocalGender = 'male' | 'female';

export function* signUpSaga({ payload: { payload, setError, toNextPage } }: ReturnType<typeof signUp>) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const age: number = yield select(selectAge);
        const urlParams: Record<string, string> = yield select(selectUrlParams);

        const data: UserInfoResponse = yield api.user.signUp({ ...payload, timezone });

        const { token, user_id, email, weight, height, gender } = data;

        sentry.setUser({ email, user_id });

        if (isEmpty(token)) {
            if (setError) {
                yield call(setError, 'basics.appError.withSupport');
            }

            return;
        }

        yield all([put(setToken(token)), put(setUserId(user_id)), put(disableIsLoading()), put(setUserEmail(email))]);

        Main.customData('Lead', { isTaboola: true });
        Main.customData('Registration', {});
        Main.customData('SIGN_UP', {});

        yield call(toNextPage);

        Core.calculateLTVCoefficient({
            age: Number(age),
            weight,
            height,
            gender: gender as LocalGender,
            utm_source: urlParams?.utm_source || 'other',
        })
            .then((coefficient) => {
                localStorage.setItem(LTV_COEFFICIENT_KEY, coefficient);
            })
            .catch((error) => {
                sentry.logError(error, SENTRY_APP, ERROR_LEVELS.ERROR);
            });
    } catch (error) {
        console.error('Api ERROR', error);
        yield put(disableIsLoading());

        let errorMessage = error?.message;

        if (error?.response?.status === 409) {
            errorMessage = error?.response?.data?.error;
        }

        if (setError) {
            yield call(setError, errorMessage || 'basics.appError.withSupport');
        }
    }
}

function* updateUserInfoSaga({ payload }: ReturnType<typeof updateUserInfo>) {
    const { data, toNextPage, fieldError } = payload;

    yield put(enableIsLoading());

    try {
        const { email }: UserInfoResponse = yield api.user.update(data);

        yield put(setUserEmail(email));

        Main.customData('registration_page__screen_click', { event_action: 'button__continue' });

        yield put(disableIsLoading());

        toNextPage();
    } catch ({ message }) {
        yield put(disableIsLoading());
        fieldError(message);
    }
}

function* getDeepLinksSaga({ payload }: ReturnType<typeof getDeepLinks>) {
    const deepLinkStringified = localStorage.getItem('deeplinkData');

    const deepLinksFromLS: DeepLinkData = deepLinkStringified ? JSON.parse(deepLinkStringified) : null;

    if (deepLinksFromLS && DAY_IN_MILLISECONDS > +new Date() - deepLinksFromLS.startTime) {
        return;
    }

    yield put(enableIsLoading());

    try {
        const link: string = yield api.user.deepLinks();

        const deepLinkData: DeepLinkData = { startTime: +new Date(), url: link };

        localStorage.setItem('deeplinkData', JSON.stringify(deepLinkData));

        payload.onSuccess && payload.onSuccess();

        yield put(setDeepLinks(deepLinkData));
    } catch (error) {
        yield put(setDeepLinks(null));
        yield put(disableIsLoading());
        console.error('Api ERROR', error);
    }
}

function* setUserProfileFromEmail() {
    const token = getParamFromUrl('token');

    if (!token) {
        yield put(setIsAutologinComplete(true));

        return;
    }

    try {
        const language = getLocalizationFromAvailable();
        const languageFromURL = getCurrentLocalization();

        localStorage.setItem('language', language || languageFromURL || DEFAULT_LANGUAGE);

        yield put(setToken(token));

        const userData: GetUserResponse = yield api.user.getUser();
        const {
            email,
            user_id,
            is_paid,
            goal,
            gender,
            fitness_level,
            target_bodytype,
            height,
            target_weight,
            weight,
            consent_mailing_active,
        } = userData;

        sentry.setUser({ email, user_id });

        const measureSystem = userData?.units?.toUpperCase?.() || METRIC;

        yield all([
            put(setUserEmail(email)),
            put(setUserId(user_id)),
            put(setIsAutologinComplete(true)),
            put(setGoal(goal || '')),
            put(setGender((gender as IGender) || 'female')),
            put(setIsPaid(is_paid)),
            put(setMeasure(measureSystem)),
            put(setCurrentWeight({ value: weight })),
            put(setTargetWeight({ value: target_weight })),
            put(setTall({ value: height })),
            put(setTargetBody(target_bodytype)),
            consent_mailing_active && put(setConsentMailingActive(consent_mailing_active)),
            fitness_level && put(setFitnessLevel(fitness_level)),
        ]);
    } catch (e) {
        console.error(e);
        yield put(setIsAutologinComplete(true));
    }
}

function* setCurrency() {
    const userCountry: string = yield select(selectCountry);

    const filteredCountry = COUNTRIES_CURRENCY_LIST.filter(({ code }) => code === userCountry);

    if (userCountry) {
        // check if country included to config list
        if (filteredCountry.length) {
            const { currency } = filteredCountry.find((item) => item.currency) ?? {
                currency: PAYMENT_DEFAULT_CURRENCY,
            };

            yield put(setCurrencyToStore(currency));
        }
    }

    if (!userCountry || !filteredCountry.length) {
        yield put(setCurrencyToStore(PAYMENT_DEFAULT_CURRENCY));
    }
}

function* updateConsentMailingSaga({ payload }: ReturnType<typeof updateConsentMailing>) {
    try {
        yield put(enableIsLoading());

        const { data, nextPage } = payload;

        yield api.user.updateConsent(data);

        const emailUpdateEvent = data?.consent_mailing_active ? 'email_updates_on' : 'email_updates_off';

        Main.customData(emailUpdateEvent, {});

        yield put(disableIsLoading());

        nextPage();
    } catch (e) {
        yield put(disableIsLoading());
        console.error(e);
    }
}

function* setUserDataSaga({ payload }: ReturnType<typeof setUserData>) {
    const { user_id, is_paid } = payload;

    yield all([put(setUserId(user_id)), put(setIsPaid(is_paid)), put(setUpdatedAtTime(new Date()))]);
}

export default [
    takeLatest(actionTypes.SIGN_UP, signUpSaga),
    takeLatest(actionTypes.UPDATE_INFO, updateUserInfoSaga),
    takeLatest(actionTypes.GET_DEEP_LINK, getDeepLinksSaga),
    takeLatest(INIT_AUTO_LOGIN, setUserProfileFromEmail),
    takeLatest(actionTypes.SET_COUNTRY, setCurrency),
    takeLatest(actionTypes.UPDATE_CONSENT_MAILING, updateConsentMailingSaga),
    takeLatest(actionTypes.SET_USER_DATA, setUserDataSaga),
];
