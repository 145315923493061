import { createContext, ReactNode } from 'react';

import { useOneTrustLoaded } from 'hooks';

const OneTrustContext = createContext<{ oneTrust: never } | null>(null);

interface OneTrustProviderProps {
    children: ReactNode;
}

export const OneTrustProvider = ({ children }: OneTrustProviderProps) => {
    const { oneTrust } = useOneTrustLoaded();

    const providerValue = oneTrust ? { oneTrust } : null;

    return <OneTrustContext.Provider value={providerValue}>{children}</OneTrustContext.Provider>;
};
