import styles from './Loader.module.css';

const Placeholder = () => {
    return (
        <div className={styles.ldsSpinner}>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

Placeholder.propTypes = {};

export default Placeholder;
