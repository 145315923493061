import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { selectPayment } from 'redux/Payment/selectors';

import { DAYS_PER_WEEK } from 'constants/payments';
import { EU_WITH_GB_COUNTRIES } from 'constants/countriesList';

import { IPaymentFlow } from 'hooks/types';

import { getCardsConfigFoxtrot16, getProductPaymentDataWithSubsHelper } from 'helpers/utilsUpdated';

import { CurrentProduct } from 'types/payments/payments';

import { Dayjs } from 'dayjs';

const HARDCODED_TRIAL_DURATION = { count: 7, context: 'day' };

export const useCardsAndCountriesDisclaimersConfig = ({
    currentProductForPayment,
    timerTime,
    products,
    pageInfo,
    defaultTrialPrice,
    withFullPrice,
}: {
    currentProductForPayment: CurrentProduct;
    timerTime: { time_to: Dayjs; seconds: number };
    products: CurrentProduct[];
    pageInfo: IPaymentFlow;
    defaultTrialPrice: number;
    withFullPrice?: boolean;
    isLatamSpecial?: boolean;
}) => {
    const { currency } = useSelector(selectPayment);
    const country = useSelector(selectCountry);

    const isEUDisclaimer = EU_WITH_GB_COUNTRIES.includes(country);

    const paymentTypeData = getProductPaymentDataWithSubsHelper(currentProductForPayment);

    const { t } = useTranslation();

    const fullPriceTrial = pageInfo?.config?.trialFullPrice || defaultTrialPrice;

    const getEUDisclaimerWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG5Foxtrot20.disclaimerTrial.EU.timerOn', {
                  trialPrice: paymentTypeData.trialPrice,
                  fullPrice: fullPriceTrial,
                  currency: currency.sign,
                  countTrial: HARDCODED_TRIAL_DURATION.count,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextTrial: HARDCODED_TRIAL_DURATION.context,
                  contextFull: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentG5Foxtrot20.disclaimerRegular.EU.timerOn', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  discountPrice: paymentTypeData.trialPrice,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              });

    const getDisclaimerWithWeekTrialBeforeTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG5Foxtrot20.disclaimerTrial.US.timerOn', {
                  fullPrice: fullPriceTrial,
                  currency: currency.sign,
                  countTrial: HARDCODED_TRIAL_DURATION.count,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextTrial: HARDCODED_TRIAL_DURATION.context,
                  contextFull: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentG5Foxtrot20.disclaimerRegular.US.timerOn', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              });

    const getEUDisclaimerWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG5Foxtrot20.disclaimerTrial.EU.timerOn', {
                  trialPrice: paymentTypeData.trialPrice,
                  fullPrice: fullPriceTrial,
                  currency: currency.sign,
                  countTrial: HARDCODED_TRIAL_DURATION.count,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextTrial: HARDCODED_TRIAL_DURATION.context,
                  contextFull: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentG5Foxtrot20.disclaimerRegular.EU.timerOff', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              });

    const getDisclaimerWithWeekTrialAfterTimer = (isTrialOneWeek?: boolean) =>
        isTrialOneWeek
            ? t('paymentFlow.paymentG5Foxtrot20.disclaimerTrial.US.timerOff', {
                  fullPrice: fullPriceTrial,
                  currency: currency.sign,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              })
            : t('paymentFlow.paymentG5Foxtrot20.disclaimerRegular.US.timerOff', {
                  fullPrice: paymentTypeData.fullPrice,
                  currency: currency.sign,
                  countFull: paymentTypeData.paymentLabel?.count,
                  contextFull: paymentTypeData.paymentLabel?.context,
              });

    const [disclaimers, setDisclaimers] = useState({ mainDisclaimer: '', secondaryDisclaimer: '' });
    const [isTrialOneWeek, setIsTrialOneWeek] = useState<boolean | undefined>();

    useEffect(() => {
        setIsTrialOneWeek(currentProductForPayment.trial === DAYS_PER_WEEK);
    }, [currentProductForPayment]);

    useEffect(() => {
        if (timerTime?.seconds > 0) {
            setDisclaimers(
                isEUDisclaimer
                    ? {
                          mainDisclaimer: getEUDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek),
                          secondaryDisclaimer: getDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek),
                      }
                    : {
                          mainDisclaimer: getDisclaimerWithWeekTrialBeforeTimer(isTrialOneWeek),
                          secondaryDisclaimer: '',
                      }
            );
        } else {
            setDisclaimers(
                isEUDisclaimer
                    ? {
                          mainDisclaimer: getEUDisclaimerWithWeekTrialAfterTimer(isTrialOneWeek),
                          secondaryDisclaimer: getDisclaimerWithWeekTrialAfterTimer(isTrialOneWeek),
                      }
                    : {
                          mainDisclaimer: getDisclaimerWithWeekTrialAfterTimer(isTrialOneWeek),
                          secondaryDisclaimer: '',
                      }
            );
        }
    }, [currentProductForPayment, isTrialOneWeek]);

    const cardsConfigure = getCardsConfigFoxtrot16(products, t, withFullPrice);

    return {
        isTrialOneWeek,
        disclaimerText: disclaimers.mainDisclaimer,
        secondaryDisclaimerText: disclaimers.secondaryDisclaimer,
        cardsConfigure,
    };
};
