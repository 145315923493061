import { configType } from 'types/testania/testaniaStore';

import * as actionTypes from './actionTypes';

export function setOnboardingConfig(onboardingConfig: configType) {
    return {
        type: actionTypes.SET_ONBOARDING_CONFIG,
        payload: onboardingConfig,
    } as const;
}

export function setTestaniaName(testName: string) {
    return {
        type: actionTypes.SET_TESTANIA_NAME,
        payload: testName,
    } as const;
}

export function setTestaniaLoginStatus(status: boolean) {
    return {
        type: actionTypes.SET_TESTANIA_LOGIN_STATUS,
        payload: status,
    } as const;
}

export function setCompleteOBs(obs: string) {
    return {
        type: actionTypes.SET_COMPLETE_OBS,
        payload: obs,
    } as const;
}

export function clearCompleteOBs() {
    return {
        type: actionTypes.CLEAR_COMPLETE_OBS,
    } as const;
}

export function setCurrenyBranchName(branchName: string) {
    return {
        type: actionTypes.SET_CURRENT_BRANCH_NAME,
        payload: branchName,
    } as const;
}

export function setFlowLink(flowLink: string) {
    return {
        type: actionTypes.SET_FLOW_LINK,
        payload: flowLink,
    } as const;
}
