import { ERROR_LEVELS, SENTRY_APP } from 'sentry-utils';
import { connect } from 'react-redux';
import { Component } from 'react';
import { SeverityLevel } from '@sentry/react';

import { selectTestaniaName, selectCurrantBranchName } from 'redux/Testania/selectors';

import sentry from 'services/Sentry/SentryInstance';
import { errorType, Extras } from 'services/Sentry/interfaces';

import { Props, State } from './interface';
import { ErrorFallback } from './ErrorFallback';

const sentryCaptureEvent = (error: Error, type: errorType, level: SeverityLevel, extra?: Extras): void => {
    // @ts-ignore
    sentry.logError(error, type, level, extra);
};

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public logError(error: Error) {
        const { testaniaName, currentBranchName } = this.props;

        sentry.logError(error, SENTRY_APP, ERROR_LEVELS.CRITICAL, { testaniaName, currentBranchName });
    }

    public componentDidCatch(error: Error) {
        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback error={this.state.error} />;
        }

        return this.props.children;
    }
}
const mapStateToProps = (state: any) => {
    return {
        testaniaName: selectTestaniaName(state),
        currentBranchName: selectCurrantBranchName(state),
    };
};

export { sentryCaptureEvent };
export default connect(mapStateToProps, null)(ErrorBoundary);
