import { useEffect, useState } from 'react';

import routesMap from 'router/routesMap';

import { replaceUnderscore } from 'helpers/utilsUpdated';
import { lazyWithPreload } from 'helpers/utils';

export interface UsePreloadNextPage {
    getNextPage?: () => {
        config: object;
        id: string;
        name: string;
    };
}

export const usePreloadNextPage = ({ getNextPage }: UsePreloadNextPage) => {
    const [nextPageId, setNextPageId] = useState<string | null>(null);

    useEffect(() => {
        getNextPageInfo(setNextPageId);
    }, []);

    useEffect(() => {
        let timerId: ReturnType<typeof setTimeout>;

        if (nextPageId) {
            const actualRoute = routesMap.find((item) => item.route === nextPageId);
            // TODO: need investigate correct type
            const NextPageComponent: any = lazyWithPreload(() => import(`pages/${actualRoute?.path}`));

            timerId = setTimeout(() => {
                NextPageComponent.preload();
            }, 0);
        }

        return () => clearInterval(timerId);
    }, [nextPageId]);

    async function getNextPageInfo(cb: (id: string) => void) {
        const nextPageInfo = await getNextPage?.();

        nextPageInfo && cb(replaceUnderscore(nextPageInfo?.id));
    }
};
