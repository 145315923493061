// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { validatePayment } from 'redux/Payment/actions';

export function useValidatePayment() {
    const dispatch = useDispatch();
    const history = useHistory();

    return (system: string, paymentMethod: number, data: any) => {
        const payload = {
            system,
            paymentMethod,
            data,
            screenId: history.location.pathname,
        };

        dispatch(validatePayment(payload));
    };
}
