import { Main } from 'wikr-core-analytics';
// eslint-disable-next-line import/named
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { replaceToUnderscore } from 'helpers/utilsUpdated';
import { getKgWeight } from 'helpers/utils';

export interface ISendAnalyticsWithEventLabel {
    url?: string;
    eventValue?: any;
}

export interface IUseAnalyticsData {
    analytic: typeof Main;
    track: (event: string) => void;
    trackClick: (event: string) => void;
    purchase: (data: any) => void;
    customData: (eventName: string, data?: any) => void;
    sendAnalyticsWithEventLabel: ({ url, eventValue }: ISendAnalyticsWithEventLabel) => void;
}

export function useAnalytics(trackFlag = false): IUseAnalyticsData {
    const location = useLocation();

    const analytic = Main;

    useEffect(() => {
        const url = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');

        if (!trackFlag) {
            analytic.track(url);
        }
    }, []);

    const track = (event: string) => {
        analytic.track(event);
    };

    const trackClick = (event: string) => {
        analytic.trackClick(event);
    };

    const purchase = (data: any) => {
        analytic.purchase(data);
    };

    const customData = (eventName: string, data: any = null) => {
        analytic.customData(eventName, data);
    };

    const sendAnalyticsWithEventLabel = ({
        url = window.location.pathname,
        eventValue: data,
    }: ISendAnalyticsWithEventLabel) => {
        const formattedUrl = url.replace(/(\/([a-z]{2})\/)|\//g, '');

        let eventValue = data;

        // for case when need send unit values
        if (typeof data === 'object' && data.value) {
            eventValue = getKgWeight(data);
        }

        const eventName = `${replaceToUnderscore(formattedUrl)}__click`;

        customData(eventName, { event_label: eventValue });
    };

    return {
        analytic,
        track,
        trackClick,
        purchase,
        customData,
        sendAnalyticsWithEventLabel,
    };
}
