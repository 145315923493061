import { Main } from 'wikr-core-analytics';
import { BLACK_LIST_HANDLED_ERROR_NAMES, IGNORE_ERRORS_LIST, IGNORE_URLS_LIST, SENTRY_AXIOS } from 'sentry-utils';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { Breadcrumb, BreadcrumbHint, EventHint, SeverityLevel, Event } from '@sentry/react';

import { checkFbBots } from 'helpers/utilsUpdated';
import { getCustomTags, checkIsOldDevices } from './helpers';

import { Extras, errorType, UserInfo, Tags, IError } from './interfaces';

declare interface iSentry {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    autoSessionTracking?: boolean;
}

export default class SentryClient {
    sentryDSN: string;
    env: 'stage' | 'prod' | string;
    release?: string;
    tracesSampleRate?: number;
    private isInited: boolean;
    private sentry;

    constructor({ sentryDSN, env, release, tracesSampleRate }: iSentry) {
        this.sentryDSN = sentryDSN;
        this.env = env || 'stage';
        this.release = release || '1.0.0';
        this.tracesSampleRate = tracesSampleRate || 1.0;
        this.isInited = false;
        this.sentry = Sentry;
    }

    init() {
        if (this.isInited) return;

        Sentry.init({
            dsn: this.sentryDSN,
            integrations: [new Integrations.BrowserTracing()],
            environment: this.env,
            debug: this.env !== 'prod',
            release: this.release,
            tracesSampleRate: this.tracesSampleRate,
            maxBreadcrumbs: 10,
            beforeBreadcrumb: (breadcrumb: Breadcrumb, hint: BreadcrumbHint) =>
                SentryClient.prepareBreadcrumb(breadcrumb, hint),
            beforeSend: (event: Event, hint: EventHint) => SentryClient.filterEventsBeforeSend(event, hint),
            ignoreErrors: IGNORE_ERRORS_LIST || [],
            denyUrls: IGNORE_URLS_LIST || [],
        });

        this.isInited = true;
    }

    public logError(error: IError, type: errorType, errorLevel: SeverityLevel, extras?: Extras, customTags?: Tags) {
        const customTagsArr = customTags || getCustomTags(error, type, extras);

        SentryClient.getAnalyticData().then((analyticData) =>
            this.sentry.withScope((scope: Sentry.Scope) => {
                extras && scope.setExtras({ ...extras, ...analyticData });

                scope.setTag('ERROR_TYPE', type);
                scope.setTag('aws_id', analyticData?.aws_id || null);
                scope.setLevel(errorLevel);

                customTagsArr.length && customTagsArr.forEach(([tag, value]) => scope.setTag(tag, value));

                this.sentry.captureMessage(SentryClient.prepareErrorByCode(error, type));
            })
        );
    }

    public setUser(userData: UserInfo) {
        this.sentry.setUser({ ...userData });
    }

    private static prepareErrorByCode(error: IError, type: errorType) {
        if (type === SENTRY_AXIOS) {
            return `[${type}] | : ${error.toString()} | ${error}`;
        }

        return `[${type}] | : ${JSON.stringify(error)} | ${error}`;
    }

    private static async getAnalyticData() {
        const isInitAmazonAnalytic = Main.checkIsAlreadyInited({ name: 'amazon' });

        return (isInitAmazonAnalytic && (await Main?.getDefaultAmazonAnalyticPurchaseData?.())) || {};
    }

    private static prepareBreadcrumb(breadcrumb: Sentry.Breadcrumb, hint: Sentry.BreadcrumbHint) {
        if (breadcrumb.level === 'error' || breadcrumb.type === 'http') {
            return { ...breadcrumb, ...hint };
        }

        return null;
    }

    private static filterEventsBeforeSend(event: Event, hint: EventHint) {
        const error = hint.originalException;

        if (
            (typeof error === 'string' && SentryClient.matchSubstring(error)) ||
            checkIsOldDevices(event) ||
            checkFbBots()
        ) {
            return null;
        }

        return event;
    }

    private static matchSubstring(error: string) {
        return BLACK_LIST_HANDLED_ERROR_NAMES.some((item) => error.includes(item));
    }
}
