import ApiClient from 'services/ApiClient';

import {
    DeepLinkData,
    HashedEmailResp,
    SignInData,
    SignUpData,
    UserInfoResponse,
    UserUpdateData,
} from 'types/user/userApiInterface';

class UserApi {
    async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
        return await ApiClient.get<Response>('user/');
    }

    async update<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.patch<Data, Response>('user/', payload);
    }

    async signIn<Data = SignInData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('sign-in/', payload);
    }

    async signUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await ApiClient.post<Data, Response>('sign-up/', payload);
    }

    async deepLinks<Response = DeepLinkData>(): Promise<Partial<Response> | unknown> {
        return await ApiClient.get<Response>('user/auth-deeplink');
    }

    async updateConsent<Data = UserUpdateData, Response = UserInfoResponse>(
        payload: Data
    ): Promise<Partial<Response> | unknown> {
        return await ApiClient.patch<Data, Response>('user/', payload);
    }

    async hashedEmail() {
        return await ApiClient.get<HashedEmailResp>('front-hash');
    }
}

export default UserApi;
