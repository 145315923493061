import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';

import { selectCountry } from 'redux/User/selectors';
import { selectHidePayPal } from 'redux/UiEffects/selectors';
import { selectCurrency, selectPaymentMethod } from 'redux/Payment/selectors';

import { CREDIT_CARD_LITERAL, LATAM_SPECIAL_MEX_CURRENCY, PAYPAL_LITERAL } from 'constants/payments';
import { MEXICAN_COUNTRY_CODE } from 'constants/countriesList';

import { useAnalytics } from 'hooks';

import { periodNormalizer, getToFixedNumber, getPriceFromCents, getTrialCountContext } from 'helpers/utilsUpdated';
import { ICUPeriodNormalizer } from 'helpers/payments';

import { PaymentMethod } from 'types/payments/payments';

type WithCheckoutAlphaDALPropType = {
    closeHandler?: () => void;
    changePaymentMethod: (type: PaymentMethod) => void;
    period?: any;
    productInfo: { price: number; start_price: number; price_per_state: number };
    events: {
        close?: string;
        track: string;
    };
    isOpen?: boolean;
    url?: string;
    withPreselect?: boolean;
    isLatamSpecial?: boolean;
};

export function useCheckoutAlphaDALExperiment({
    closeHandler,
    changePaymentMethod,
    period,
    events,
    isOpen,
    productInfo: { price, start_price, price_per_state },
    url = window.location.pathname,
    withPreselect,
    isLatamSpecial = false,
}: WithCheckoutAlphaDALPropType) {
    const { track, customData, sendAnalyticsWithEventLabel } = useAnalytics(true);

    const { t } = useTranslation();

    useEffect(() => {
        if (isOpen) {
            initPaymentMethod(actualPaymentType);
            track(events.track);
            track('checkout');
        }
    }, [isOpen]);

    const isHidePayPal = useSelector(selectHidePayPal);
    const currency = useSelector(selectCurrency);
    const country = useSelector(selectCountry);
    const activeOption = useSelector(selectPaymentMethod);

    const finalCurrency = isLatamSpecial && country === MEXICAN_COUNTRY_CODE ? LATAM_SPECIAL_MEX_CURRENCY : currency;

    const actualPaymentType = useMemo(() => {
        if (!withPreselect) {
            return isHidePayPal ? CREDIT_CARD_LITERAL : PAYPAL_LITERAL;
        }

        return activeOption;
    }, [activeOption, isHidePayPal]);

    const fullPrice = getPriceFromCents(price);
    const personalizeFullPrice = getPriceFromCents(start_price);
    const discountPrice = getToFixedNumber(personalizeFullPrice - fullPrice);
    const periodType = periodNormalizer(period);
    const ICUPeriodType = ICUPeriodNormalizer(period);
    const pricePerDay = getPriceFromCents(price_per_state);
    const discount = Math.round((1 - fullPrice / personalizeFullPrice) * 100);

    const sendAnalyticData = (eventValue: string) => {
        sendAnalyticsWithEventLabel({
            url,
            eventValue,
        });
    };

    const initPaymentMethod = (type: PaymentMethod) => {
        changePaymentMethod(type);
    };

    const handleChange = (type: PaymentMethod) => {
        if (activeOption !== type) {
            initPaymentMethod(type);
            sendAnalyticData(type);
        }
    };

    const handleOnClose = () => {
        closeHandler?.();

        sendAnalyticData('Close');
        events.close && customData(events.close, {});
    };

    const disclaimer = t('paymentFlow.checkoutFoxtrot1.ICU.disclaimer.cabinetLink', {
        discountPrice: `${finalCurrency.sign}${fullPrice}`,
        fullPrice: `${finalCurrency.sign}${personalizeFullPrice}`,
        count: ICUPeriodType.count,
        context: ICUPeriodType.context,
    });

    const disclaimerIntro = t('paymentFlow.checkoutFoxtrot4.ICU.disclaimerIntro.cabinetLink', {
        discountPrice: `${finalCurrency.sign}${fullPrice}`,
        fullPrice: `${finalCurrency.sign}${personalizeFullPrice}`,
        count: ICUPeriodType.count,
        context: ICUPeriodType.context,
    });

    const disclaimerRegular = t('paymentFlow.checkoutFoxtrot4.ICU.disclaimerRegular.cabinetLink', {
        discountPrice: `${finalCurrency.sign}${fullPrice}`,
        fullPrice: `${finalCurrency.sign}${personalizeFullPrice}`,
        count: ICUPeriodType.count,
        context: ICUPeriodType.context,
    });

    const planTitle = t('paymentFlow.checkoutFoxtrot1.ICU.planTitle', {
        count: ICUPeriodType.count,
        context: ICUPeriodType.context,
    });

    const trialKeyData = getTrialCountContext(ICUPeriodType.count, ICUPeriodType.context);

    const trialPlan = t('paymentFlow.checkoutFoxtrot16.trial.priceLabel', {
        count: trialKeyData?.count,
        context: trialKeyData?.context,
    });

    return {
        personalizeFullPrice,
        fullPrice,
        isHidePayPal,
        paymentMethod: { creditCard: CREDIT_CARD_LITERAL, paypal: PAYPAL_LITERAL },
        discount,
        periodType,
        activeOption,
        currencyName: finalCurrency.name,
        discountPrice,
        handleChange,
        handleOnClose,
        pricePerDay,
        disclaimer,
        disclaimerIntro,
        disclaimerRegular,
        planTitle,
        trialPlan,
    };
}
