import { FormType } from '@solidgate/react-sdk';

export const PAYMENT_EVENT_NAMES = {
    applebtn: 'apple_pay',
    googlebtn: 'google_pay',
    form: 'bank_card',
};

export const getFormParams = (isHideCustomPaymentButton: boolean, autoFocus = true) => ({
    width: '100%',
    submitButtonText: 'Continue',
    headerText: 'Enter your debit or credit card details (from merchant)',
    titleText: 'Card info (from merchant)',
    formTypeClass: FormType.Default,
    allowSubmit: !isHideCustomPaymentButton,
    autoFocus,
});

export const getApplePayButtonParams = (isShowApplePayMethod: boolean) => ({
    color: 'black',
    enabled: isShowApplePayMethod,
});

export const getGooglePayButtonParams = (isShowGooglePayMethod: boolean) => ({
    type: 'buy',
    enabled: isShowGooglePayMethod,
});

export const getGooglePayPlainButtonParams = (isShowGooglePayMethod: boolean) => ({
    type: 'plain',
    enabled: isShowGooglePayMethod,
});
