import { useTranslation } from 'react-i18next';

import Title from 'components/Title';
import Container from 'components/Container';

export function ErrorFallback({ error }) {
    const { t } = useTranslation();

    return (
        <Container staticHeader>
            <Title h4New globalClassNames={['mb-8', 'centered']}>
                {t('basics.appError')}
            </Title>
            <pre>{error?.message}</pre>
        </Container>
    );
}
