import ROUTES_MAP from 'router/routesMap';

type RouteItem = {
    path: string;
    route: string;
};

export const formatRoutesList = (pathName: string) => {
    const formattedRoutesList: { route: string }[] = [];

    ROUTES_MAP.forEach((item: RouteItem) => {
        if (item.path.includes(pathName)) {
            formattedRoutesList.push({ route: item.route });
        }
    });

    return formattedRoutesList;
};

export const setRoutesList = () => {
    window.ROUTES_LIST = {
        onboardingPages: formatRoutesList('OnboardingPages'),
        paymentPages: formatRoutesList('PaymentPages'),
        startPages: formatRoutesList('StartPages'),
        upsellPages: formatRoutesList('UpsellPages'),
        resultPages: formatRoutesList('ResultPages'),
    };
};
