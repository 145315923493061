import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectPayment } from 'redux/Payment/selectors';
import { setCurrentProduct } from 'redux/Payment/actions';

import { IUseSetCurrentProductAfterTimer } from '../types';

export const useSetCurrentProductAfterTimer = ({
    isTimerEnd,
    products,
    setCurrentProductForPayment,
}: IUseSetCurrentProductAfterTimer) => {
    const dispatch = useDispatch();

    const { currentProduct } = useSelector(selectPayment);

    useEffect(() => {
        if (isTimerEnd) {
            const regularProduct = products.find(
                ({ period, id, trial }) =>
                    currentProduct?.id !== id && currentProduct?.period === period && currentProduct?.trial === trial
            );

            if (regularProduct) {
                dispatch(setCurrentProduct(regularProduct));
                setCurrentProductForPayment(regularProduct);
            }
        }
    }, [isTimerEnd]);
};
