import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';

// helpers
import config from 'config';

import { selectUser } from 'redux/User/selectors';
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectTestaniaName } from 'redux/Testania/selectors';
import { selectUrlParams } from 'redux/Onboadring/selectors';
import { skipOnboarding } from 'redux/Onboadring/actions';

import { LTV_COEFFICIENT_KEY, DEFAULT_LTV_COEFFICIENT } from 'constants/payments';

import { initAmazonAnalytic, initHotjar } from 'services/Analytics';

import { setRoutesList } from 'helpers/setRoutesList';
import { handleCombinedCodes } from 'helpers/combinedCodes';
import { setAnalyticData, setUserPlatform, reload } from './helpers';

const PROD_ENV = 'prod';

export function useAppInit() {
    const dispatch = useDispatch();

    const prevCountry = useRef<string | null>(null);
    const prevAbTestName = useRef<string | null>(null);
    const prevUserId = useRef<string | number | null>(null);

    const { country, user_id: userId } = useSelector(selectUser);
    const abTestName = useSelector(selectTestaniaName);
    const urlParams = useSelector(selectUrlParams);

    const setNewPropsToRef = () => {
        prevCountry.current = country;
        prevAbTestName.current = abTestName;
        prevUserId.current = userId;
    };

    useEffect(() => {
        const testaniaStatus = localStorage.getItem('testaniaResponseStatus');

        localStorage.setItem(LTV_COEFFICIENT_KEY, String(DEFAULT_LTV_COEFFICIENT));

        setNewPropsToRef();
        setAnalyticData({ country, abTestName, userId });
        setUserPlatform(dispatch);

        initAmazonAnalytic();

        if (config.ENV !== PROD_ENV) {
            handleCombinedCodes(handleSkipOnboarding, ['ShiftLeft', 'KeyO', 'KeyB']);
        }

        if (testaniaStatus) {
            dispatch(setAppLoader(false));
        }

        initHotjar();

        setRoutesList();
    }, []);

    useEffect(() => {
        if (prevCountry.current !== country || prevAbTestName.current !== abTestName || prevUserId.current !== userId) {
            setAnalyticData({ country, abTestName, userId });
            setNewPropsToRef();
        }
    }, [country, abTestName, urlParams, userId]);

    const handleSkipOnboarding = () => {
        dispatch(
            skipOnboarding({
                reload,
            })
        );
    };
}
