import * as action from './actions';

import * as actionTypes from './actionTypes';

import { DEFAULT_COUNTRY } from 'constants/countriesList';

import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonInterfaces';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UserStore = {
    email: null,
    orderId: null,
    user_id: null,
    emailStatus: {
        isValid: false,
        message: '',
    },
    isLoading: false,
    token: null,
    payment_type: null,
    guideId: null,
    loader: false,
    country: localStorage.getItem('country') || DEFAULT_COUNTRY,
    lastUpdateAt: null,
    isPaid: false,
    platform: null,
    isAutologinComplete: false,
    deepLink: JSON.parse(localStorage.getItem('deeplinkData') || 'false') || null,
    isConsentMailingActive: false,
    isConsentsMailingActiveDisplay: true,
    error: null,
};

const UserReducer = (state = initialState, action: ActionType): UserStore => {
    switch (action.type) {
        case actionTypes.SIGN_UP:

        case actionTypes.UPDATE_INFO:
            return {
                ...state,
                isLoading: true,
            };

        case actionTypes.SET_LOADER:
            return { ...state, loader: action.payload };

        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };

        case actionTypes.SET_ORDER_ID:
            return { ...state, orderId: action.payload };

        case actionTypes.SET_USER_ID:
            return { ...state, user_id: action.payload };

        case actionTypes.SET_GUIDE_ID:
            return { ...state, guideId: action.payload };

        case actionTypes.SET_USER_TOKEN:
            localStorage.setItem('token', action.payload);

            return { ...state, token: action.payload };

        case actionTypes.REMOVE_USER_TOKEN:
            localStorage.removeItem('token');

            return { ...state, token: null };

        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };

        case actionTypes.UPDATE_CONSENT_MAILING:
            return { ...state, isLoading: true };

        case actionTypes.SET_IS_PAID:
            return { ...state, isPaid: action.payload };

        case actionTypes.SET_UPDATED_AT_TIME:
            return { ...state, lastUpdateAt: action.payload };

        case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
            return { ...state, isAutologinComplete: action.payload };

        case actionTypes.SET_PLATFORM:
            return { ...state, platform: action.payload };

        case actionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };

        case actionTypes.SET_DEEP_LINK:
            return { ...state, deepLink: action.payload, isLoading: false };

        case actionTypes.SET_CONSENT_MAILING_ACTIVE:
            return { ...state, isConsentMailingActive: action.payload };

        case actionTypes.SET_CONSENT_MAILING_ACTIVE_DISPLAY:
            return { ...state, isConsentsMailingActiveDisplay: action.payload };

        default:
            return state;
    }
};

export default UserReducer;
