/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';

import api from 'api';

import * as actionTypes from 'redux/Payment/actionTypes';
import { setPaymentErrorToStore } from 'redux/Payment/actions';

import { IPaymentFailToReteno } from 'types/payments/paymentApiInterfaces';

export function* sendPayFailureToReteno({ payload }: ReturnType<typeof setPaymentErrorToStore>) {
    try {
        const response: IPaymentFailToReteno = yield api.payment.paymentFailToReteno(payload);

        yield put(setPaymentErrorToStore(response));
    } catch ({ error }) {
        console.error('error', error);
    }
}

export const setPaymentErrorCodeToReteno = [takeLatest(actionTypes.SET_PAYMENT_RETENO_ERROR, sendPayFailureToReteno)];
