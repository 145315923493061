// eslint-disable-next-line import/named
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { selectFlowLink } from 'redux/Testania/selectors';
import { setFlowLink } from 'redux/Testania/actions';
import { setGender, setUrlParams as setUrlParamsToStore } from 'redux/Onboadring/actions';

import { getUrlParams as getUrlParamsFromUrl } from 'helpers/utils';

import { IGender } from 'types/commonInterfaces';

import PublicRouter from './routers/public';

export function Routers() {
    const dispatch = useDispatch();

    const params = new URLSearchParams(window.location.search);
    const paramsGender = params.get('gender') as IGender;

    const flowLink = useSelector(selectFlowLink);

    useEffect(() => {
        if (!flowLink) {
            dispatch(setFlowLink(location.href));
        }

        const urlParamsFromURL = getUrlParamsFromUrl();

        if (urlParamsFromURL) {
            dispatch(setUrlParamsToStore(urlParamsFromURL));
        }
    }, []);

    if (paramsGender) {
        dispatch(setGender(paramsGender));
    }

    return (
        // @ts-ignore
        <Switch>
            {PublicRouter.map((route) => (
                // @ts-ignore
                <Route key={route.key} path={route.path} render={route.render} exact={route.exact} />
            ))}
        </Switch>
    );
}
