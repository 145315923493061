import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

interface IUsePaymentTimer {
    onTimerEnd: () => void;
    timerTime: number;
    discount: number;
    thisOffer?: boolean;
}

export const usePaymentTimer = ({ onTimerEnd, timerTime, discount, thisOffer }: IUsePaymentTimer) => {
    const [isActiveTimer, setIsActiveTimer] = useState(false);

    useEffect(() => {
        +timerTime && setIsActiveTimer(timerTime >= 0);

        if (timerTime < 0) {
            setIsActiveTimer(false);
        }
    }, [timerTime]);

    const timerEndHandler = () => {
        onTimerEnd();

        setIsActiveTimer(false);
    };

    const { t } = useTranslation();
    const timerText = thisOffer
        ? t('paymentFlow.paymentBa.planReady.timer')
        : t('paymentFlow.paymentIntroductory.discountReserved', { count: discount });

    return {
        isActiveTimer,
        timerText,
        timerEndHandler,
    };
};
