import Core, { DataProvider } from 'testania';
// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactElement, useEffect, useState } from 'react';

import { selectUser } from 'redux/User/selectors';
import { setCountry } from 'redux/User/actions';
import { selectHideFrontChat, selectHidePayPal } from 'redux/UiEffects/selectors';
import { setAppLoader } from 'redux/UiEffects/actions';
import { selectTestania } from 'redux/Testania/selectors';
import { setOnboardingConfig, setTestaniaName } from 'redux/Testania/actions';
import { selectUrlParams } from 'redux/Onboadring/selectors';
import { initAutoLogin, setOnboardingScreensList } from 'redux/Onboadring/actions';

import { DEFAULT_COUNTRY } from 'constants/countriesList';

import { validateCountry } from 'helpers/utilsUpdated';
import {
    getScreenList,
    getIsLogin,
    getUserStatus,
    setCurrentBranchToStore,
    setCompleteOBsToStore,
    setTestaniaLoginStatusToStore,
    setInitMeasure,
    isInstructionPage,
    clearCompleteOBsToStore,
} from './config/helpers';

import { validateFlow } from './config/flowValidation';
import { TESTANIA_INIT_CONFIG } from './config/flowInitConfig';

function InitTestania(): ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();

    const isHidePayPal = useSelector(selectHidePayPal);
    const isHideFrontChat = useSelector(selectHideFrontChat);
    const urlParams = useSelector(selectUrlParams);

    window.HIDE_PAYPAL = Number(isHidePayPal);
    window.HIDE_FRONT_CHAT = Number(isHideFrontChat);

    const [startStatus, setStartStatus] = useState(false);

    const { isAutologinComplete } = useSelector(selectUser);

    const token = localStorage.getItem('token') || null;
    const isResetStore = localStorage.getItem('isResetStore') || null;
    const isSkipStartPage = (urlParams as Record<string, string>)?.['skipStartPage'] === 'true';

    if (isResetStore) {
        dispatch({ type: 'RESET_STATE' });
    }

    const { testaniaLoginStatus, completeOBs, testaniaName } = useSelector(selectTestania);

    const toNextPage = async (path: string) => history.push(path);

    const initTestania = async () => {
        const responseFromTestania = await Core.init(TESTANIA_INIT_CONFIG);

        if (responseFromTestania) {
            const { country, testName } = responseFromTestania;

            dispatch(setAppLoader(false));
            localStorage.setItem('testaniaResponseStatus', '1');

            if (country) {
                const countryValue = validateCountry(country)
                    ? country
                    : localStorage.getItem('country') || DEFAULT_COUNTRY;

                dispatch(setCountry(countryValue));
                await setInitMeasure(countryValue, dispatch);
            }
            if (testName) await dispatch(setTestaniaName(testName));
        }

        await responseFromTestania?.callback();
    };

    const provideData = async () => {
        await DataProvider.provideInitialData({
            loginStatus: await getIsLogin(testaniaLoginStatus, token),
            startStatus,
            startAction: async () => setStartStatus(true),
            toNextPage,
            pathTestaniaPaidFinish: 'registration-page',
            pathTestaniaRegistrationFinish: 'thank-you',
            // TODO: need to add param is_password_set from BE
            getUserStatus: () => getUserStatus(dispatch, token),
            // TODO: need investigate types in lib
            // @ts-ignore
            getToken: async () => token,
            getCompleteOBs: async () => completeOBs,
            // @ts-ignore
            getCurrentBranch: async () => testaniaName,
            setCurrentBranch: async (branch: string) => setCurrentBranchToStore(branch, dispatch),
            setCompleteOBs: async (branch: string) => setCompleteOBsToStore(branch, dispatch),
            clearCompleteOBs: () => clearCompleteOBsToStore(dispatch),
            setLoginStatus: async (status: boolean) => setTestaniaLoginStatusToStore(status, dispatch),
            customFilter: validateFlow,
            isSkipStartPage,
        });
    };

    const initTestaniaDependencies = async () => {
        await provideData();
        await initTestania();

        const onboardingFlow = DataProvider.getOnboardingFlow();

        const screenList = getScreenList(onboardingFlow);

        dispatch(setOnboardingScreensList(screenList));

        dispatch(setOnboardingConfig(onboardingFlow));
    };

    useEffect(() => {
        provideData();
    }, [token, completeOBs, testaniaName]);

    useEffect(() => {
        dispatch(initAutoLogin());
    }, []);

    useEffect(() => {
        if (isAutologinComplete && !isInstructionPage()) {
            try {
                initTestaniaDependencies();
            } catch (e) {
                console.error(
                    `Error with init testania, check initial methods in - initTestaniaDependencies \n \n ${e}`
                );
            }
        }
    }, [isAutologinComplete]);

    return <></>;
}

export default InitTestania;
