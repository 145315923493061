/* eslint-disable max-lines */
import * as actionTypes from './actionTypes';

import {
    SignUpData,
    UserInfoResponse,
    IUpdateUserInfoSaga,
    DeepLinkData,
    IUpdateConsentMailing,
} from 'types/user/userApiInterface';

export function setUserData(payload: UserInfoResponse) {
    return {
        type: actionTypes.SET_USER_DATA,
        payload,
    } as const;
}

export function setIsPaid(status: boolean) {
    return {
        type: actionTypes.SET_IS_PAID,
        payload: status,
    } as const;
}

export function setUpdatedAtTime(time: Date) {
    return {
        type: actionTypes.SET_UPDATED_AT_TIME,
        payload: time,
    } as const;
}

export function signUp(data: SignUpData) {
    return {
        type: actionTypes.SIGN_UP,
        payload: data,
    } as const;
}

export function getDeepLinks(payload: { onSuccess: () => void }) {
    return { type: actionTypes.GET_DEEP_LINK, payload } as const;
}

export function setDeepLinks(linkData: DeepLinkData | null) {
    return { type: actionTypes.SET_DEEP_LINK, payload: linkData } as const;
}

export function setLoader(payload: boolean) {
    return {
        type: actionTypes.SET_LOADER,
        payload: payload,
    } as const;
}

export function updateConsentMailing(payload: IUpdateConsentMailing) {
    return {
        type: actionTypes.UPDATE_CONSENT_MAILING,
        payload: payload,
    } as const;
}

export function updateUserInfo(payload: IUpdateUserInfoSaga) {
    return {
        type: actionTypes.UPDATE_INFO,
        payload: payload,
    } as const;
}

export function updateUserInfoFail(error: unknown) {
    return {
        type: actionTypes.UPDATE_INFO_FAIL,
        payload: error,
    } as const;
}

export function setOrderId(orderId: string) {
    return {
        type: actionTypes.SET_ORDER_ID,
        payload: orderId,
    } as const;
}

export function setUserId(id: string | number) {
    return {
        type: actionTypes.SET_USER_ID,
        payload: id,
    } as const;
}

export function setUserEmail(email: string) {
    return {
        type: actionTypes.SET_EMAIL,
        payload: email,
    } as const;
}

export function setGuide(id: string) {
    return {
        type: actionTypes.SET_GUIDE_ID,
        payload: id,
    } as const;
}

export function setToken(token: string) {
    return {
        type: actionTypes.SET_USER_TOKEN,
        payload: token,
    } as const;
}

export function removeToken() {
    return {
        type: actionTypes.REMOVE_USER_TOKEN,
    } as const;
}

export function setCountry(country: string) {
    return {
        type: actionTypes.SET_COUNTRY,
        payload: country,
    } as const;
}

export function setIsAutologinComplete(status: boolean) {
    return {
        type: actionTypes.SET_IS_AUTOLOGIN_COMPLETE,
        payload: status,
    } as const;
}

export function setPlatform(platform: string) {
    return {
        type: actionTypes.SET_PLATFORM,
        payload: platform,
    } as const;
}

export function enableIsLoading() {
    return {
        type: actionTypes.SET_IS_LOADING,
        payload: true,
    } as const;
}

export function disableIsLoading() {
    return {
        type: actionTypes.SET_IS_LOADING,
        payload: false,
    } as const;
}

export function setConsentMailingActive(status: boolean) {
    return {
        type: actionTypes.SET_CONSENT_MAILING_ACTIVE,
        payload: status,
    } as const;
}

export function setConsentMailingActiveDisplay(status: boolean) {
    return {
        type: actionTypes.SET_CONSENT_MAILING_ACTIVE_DISPLAY,
        payload: status,
    } as const;
}
